import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="services-container">
      <h2 className="services-title">OUR <span className="highlight">SERVICES</span></h2>
      <div className="services-cards">
        <div className="service-card service-card-orange">
          <img src="/Webdev.png" alt="Website Development" className="service-icon" />
          <h3 className="service-title">WEBSITE DEVELOPMENT</h3>
          <p className="service-description">
          Saptam Technologies builds custom, responsive, and high-performance websites tailored to your business. Our services include SEO optimization, e-commerce solutions, CMS integration, and speed optimization, ensuring a seamless user experience, enhanced functionality, and scalability for future growth.
          </p>
        </div>
        <div className="service-card service-card-black">
          <img src="/Graphicdesigning.png" alt="Graphic Designing" className="service-icon" />
          <h3 className="service-title">GRAPHIC DESIGNING</h3>
          <p className="service-description">
            Our Graphic Designing services create visually compelling branding, logos, social media graphics, and more. We craft designs that communicate your brand's message, ensuring consistency, creativity, and a memorable user experience that resonates with your target audience and drives engagement.
            </p>
        </div>
        <div className="service-card service-card-orange">
          <img src="/SocialMedia.png" alt="Social Media Handling" className="service-icon" />
          <h3 className="service-title">SOCIAL MEDIA HANDLING</h3>
          <p className="service-description">
          We manage your social media presence across platforms like Facebook, Instagram, and LinkedIn. Our services include content creation, community engagement, and strategy development, aimed at increasing brand awareness, fostering customer relationships, and driving conversions through data-driven campaigns.
          </p>
        </div>
        <div className="service-card service-card-black">
          <img src="/Ads.png" alt="Google & Facebook Ads" className="service-icon" />
          <h3 className="service-title">GOOGLE & FACEBOOK ADS</h3>
          <p className="service-description">
            We design and manage targeted Google and Facebook Ads campaigns to increase traffic and conversions. Our team optimizes ad copy, audience targeting, and budget allocation to ensure maximum ROI, delivering cost-effective, high-impact advertising solutions tailored to your business goals.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
