import React from 'react';
import { Link } from 'react-scroll'; 
import './Home.css';

function Home() {
  return (
    <>
      <section className="home-section">
        <div className="home-content">
          <div className="text-content">
            <h1>Digital Marketing <span className="and"> and </span> Web Development</h1>
            <h2>solutions to fuel your business growth.</h2>
            <p>Elevate your brand, engage your audience, and achieve success online!</p>

            {/* Use Link to scroll to the services section */}
            <Link
              to="services"  // Target the section with the ID 'services'
              spy={true}      // Enables scrolling tracking
              smooth={true}   // Enables smooth scrolling
              duration={200}  // Time duration for scroll
            >
              <button className="explore-button">Explore Our Services</button>
            </Link>

          </div>
          <div className="image-content">
            <img src="/homePage.png" alt="Web Developer Illustration" />
          </div>
        </div>
        <div className="footer-icons">
          <img src="/GoogleIcons.png" alt="Google" />
          <span className="img1">
            <img src="/GoogleA.png" alt="Google Analytics" />
          </span>
          <img src="/GoogleAd.png" alt="Google Ads" />
          <img src="/Meta.png" alt="Meta" />
          <img src="/facebook.png" alt="Facebook" />
          <img src="/instagram.png" alt="Instagram" />
        </div>
      </section>

      {/* Sales Funnel Section */}
      <section className="sales-funnel-section">
        <div className="sales-funnel-content">
          <div className="funnel-image">
            <img src="/imageHome.png" alt="Sales Funnel Illustration" />
          </div>
          <div className="sales-funnel-text">
            <h1>
              Turning <span className="highlight">Leads</span> into <span className="highlight">Lifelong Customers</span> — 
              We Help You Master Every Stage of the <span className="highlight">Sales Funnel</span>!
            </h1>
            <p>Ready to Grow? Let Our Full-Service Marketing Team Supercharge Your Brand’s Online Presence!</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
