import React from 'react';
import './Footer.css';
import { Link as RouterLink } from 'react-router-dom';
import { MdEmail, MdLocationOn, MdPhone, MdCopyright } from 'react-icons/md';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section contact-message">
          <h3>We'd Love to Hear From You!</h3>
          <p>
            At Saptam Technologies, we specialize in web development, graphic design, and targeted Google/Facebook ads. Let’s work together to build a stunning online presence and drive growth through creative solutions. <span>Contact us today</span> to elevate your business with our expert services.
          </p>
        </div>

        <div className="footer-section organization-details">
          <h4>Saptam Technologies</h4>
          <p>
            <a href="mailto:info@saptamtech.com">
              <MdEmail /> info@saptamtech.com
            </a>
          </p>
          <p><MdPhone /> +91 7357932921</p>
          <p><MdLocationOn /> Sector 1, Malviya Nagar, Jaipur, India, 302017</p>
        </div>

        <div className="footer-section quick-links">
          <h4>Quick Links</h4>
          <p>
            <a
              href="https://www.facebook.com/profile.php?id=61567117079715&mibextid=JRoKGi"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link facebook"
            >
              <img
                src="/facebook.png" // Image path in the public folder
                alt="Facebook"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
              Facebook
            </a>
          </p>
          <p>
            <a
              href="https://www.instagram.com/saptam_technologies/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link instagram"
            >
              <img
                src="/instagram.png" // Image path in the public folder
                alt="Instagram"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              /> Instagram
            </a>
          </p>
          <p>
            <a
              href="https://www.linkedin.com/company/saptam-technologies"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
            >
              <img
                src="/Linkedinn.png" // Image path in the public folder
                alt="Linkedin"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              /> LinkedIn
            </a>
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-links">
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          <RouterLink to="/sitemap" className="link">
            Sitemap
          </RouterLink>
        </div>
        <div className="footer-copyright">
          <p>
            <MdCopyright style={{ marginRight: '4px' }} />
            {new Date().getFullYear()} Saptam Technologies
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
