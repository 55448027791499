import React from 'react';
import './sitemap.css'; 

function Sitemap() {
  return (
    <div className="sitemap-container">
      <h1>Sitemap</h1>
      <h2>Saptam Technologies</h2>
      <div className="map-container">
        <iframe
          title="Google Map of Saptam Technologies' Location"
          src="https://www.google.com/maps?q=26.88103628822111,75.77991941452752&z=15&output=embed"
          width="600"
          height="450"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

      <h2>Main Pages</h2>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/">About Us</a></li>
        <li><a href="/">Services</a></li>
        <li><a href="/">Portfolio</a></li>
        <li><a href="/">Contact Us</a></li>
      </ul>

      <h2>Other Pages</h2>
      <ul>
        <li><a href="/terms">Terms & Conditions</a></li>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
      </ul>
    </div>
  );
}

export default Sitemap;
