import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom'; 
import { Link as ScrollLink } from 'react-scroll';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const isHomePage = location.pathname === '/';

  return (
    <nav className="navbar">
      <div className="logo">
        <RouterLink to="/">
          <img src="/LogoSaptam.png" alt="Logo" />
        </RouterLink>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={closeMenu}></div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Home
            </ScrollLink>
          ) : (
            <RouterLink to="/" onClick={closeMenu}>
              Home
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="about"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              About Us
            </ScrollLink>
          ) : (
            <RouterLink to="/" state={{ scrollTo: 'about' }} onClick={closeMenu}>
              About Us
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="services"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Services
            </ScrollLink>
          ) : (
            <RouterLink to="/" state={{ scrollTo: 'services' }} onClick={closeMenu}>
              Services
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="portfolio"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Portfolio
            </ScrollLink>
          ) : (
            <RouterLink to="/" state={{ scrollTo: 'portfolio' }} onClick={closeMenu}>
              Portfolio
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              duration={200}
              onClick={closeMenu}
            >
              Contact Us
            </ScrollLink>
          ) : (
            <RouterLink to="/" state={{ scrollTo: 'contact' }} onClick={closeMenu}>
              Contact Us
            </RouterLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              duration={200}
              className="button-link"
              onClick={closeMenu}
            >
              Get a Free Consultation
            </ScrollLink>
          ) : (
            <RouterLink
              to="/"
              state={{ scrollTo: 'contact' }}
              className="button-link"
              onClick={closeMenu}
            >
              Get a Free Consultation
            </RouterLink>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
