import React, { useState } from 'react';
import './ContactUs.css';

function ContactUs() {
  // State to hold form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    service: '',
    message: ''
  });

  // Function to handle changes in form inputs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sending data using POST method
    const response = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      alert('Message sent successfully!');
      // Clear the form after successful submission
      setFormData({
        name: '',
        email: '',
        contactNumber: '',
        service: '',
        message: ''
      });
    } else {
      alert('Error sending message. Please try again.');
    }
  };

  return (
    <div className="contact-us-container">
      <h2>Contact <span>Us</span></h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-left">
          <div className="form-group">
            <label>Your Name*</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Your Email Address*</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Contact Number*</label>
            <input
              type="tel"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-right">
          <div className="form-group">
            <label>The Services You're Interested In*</label>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a service</option>
              <option value="Website Development">Website Development</option>
              <option value="Graphic Designing">Graphic Designing</option>
              <option value="Social Media Handling">Social Media Handling</option>
              <option value="Google & Facebook Ads">Google & Facebook Ads</option>
            </select>
          </div>
          <div className="form-group">
            <label>Message*</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
        </div>
        <div className="submit-container">
          <button type="submit" className="submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
