import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Use the Navbar component
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import Contact from './components/ContactUs';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Sitemap from './components/Sitem'; // Ensure Sitemap component is correctly imported
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <section id="home">
                  <Home />
                </section>
                <section id="about">
                  <AboutUs />
                </section>
                <section id="services">
                  <Services />
                </section>
                <section id="portfolio">
                  <Portfolio />
                </section>
                <section id="testimonials">
                  <Testimonials />
                </section>
                <section id="Faq">
                  <Faq />
                </section>
                <section id="contact">
                  <Contact />
                </section>
                <Footer />
              </>
            }
          />
          <Route path="/sitemap" element={<Sitemap />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
