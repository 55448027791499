import React, { useState } from 'react';
import './Testimonials.css';

const testimonialsData = [
  {
    company: "INNX",
    quote: "Saptam Technologies delivered a seamless, responsive website for our business. They understood our needs perfectly and provided a modern design with excellent functionality. The project was completed on time and exceeded expectations. Highly recommended!",
    name: "Sarah P.",
    position: "CEO",
    avatar: "/images1.png"
  },
  {
    company: "Crafty Creations",
    quote: "Saptam Technologies created a stunning logo and brand materials for us. Their design team is creative, professional, and attentive to detail. Our branding has never looked better. We’re thrilled with the results!",
    name: "Michael R.",
    position: "Owner",
    avatar: "/images1.png"
  },
  {
    company: "G-Glow Solutions",
    quote: "Working with Saptam Technologies for social media management has been fantastic. They’ve boosted our online presence with great content and increased engagement. Our brand visibility has grown significantly. They truly know social media!",
    name: "Jessica T.",
    position: "Marketing Director",
    avatar: "/images1.png"
  }
];

function Testimonials() {
  // State to track the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle previous button click
  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1));
  };

  // Function to handle next button click
  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">
        Still Hesitant? Let Our <span>Happy Clients'<br/>Stories</span> Convince You!
      </h2>

      {/* Carousel Container */}
      <div className="testimonials-carousel">
        <div className="testimonials-cards">
          {/* Map through testimonials and only show the one at the current index */}
          <div className="testimonial-card">
            <h3 className="company-name">{testimonialsData[currentIndex].company}</h3>
            <p className="quote">"{testimonialsData[currentIndex].quote}"</p>
            <div className="user-info">
              <div className="user-avatar">
                <img src={testimonialsData[currentIndex].avatar} alt={testimonialsData[currentIndex].name} />
              </div>
              <div>
                <p className="user-name">{testimonialsData[currentIndex].name}</p>
                <p className="user-position">{testimonialsData[currentIndex].position}</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Pagination Dots */}
        <div className="pagination-dots">
          {testimonialsData.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
            ></span>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="carousel-navigation">
        <button className="prev-btn" onClick={prevTestimonial}>❮</button>
        <button className="next-btn" onClick={nextTestimonial}>❯</button>
      </div>
    </div>
  );
}

export default Testimonials;
